import $ from 'jquery';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  if ($('.accordion').length > 0) {
    $('.accordion__accordion.open > .accordion__sub-rows').slideDown();

    $(document).on('click', '.accordion-toggle', function () {
      const accordionToggle = $(this);
      accordionToggle.parent('.accordion__accordion').toggleClass('open');

      if (accordionToggle.parent('.accordion__accordion').hasClass('open')) {
        accordionToggle.next('.accordion__sub-rows').slideDown();
        accordionToggle.attr('aria-expanded', true);
      } else {
        accordionToggle.next('.accordion__sub-rows').slideUp();
        accordionToggle.attr('aria-expanded', false);
      }
    });
  }
});
